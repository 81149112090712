import { usePublicApiClient } from '@apiClients';
import { ONE_HOUR } from '@constants';
import { useQuery } from '@tanstack/react-query';
import { ProjectTheme } from 'tokensoft-shared-types';

export const useGetProjectTheme = () => {
  const fetchWithClient = usePublicApiClient();

  return useQuery<ProjectTheme>({
    queryKey: ['project/theme'],
    queryFn: async () => {
      const projectTheme = await fetchWithClient('project/theme');

      const parsedProjectTheme: ProjectTheme = {
        version: projectTheme.version,
        logo: projectTheme.logo,
        logoSmall: projectTheme.logoSmall,
        favicon: projectTheme.favicon,
        fonts: {
          title: projectTheme.titleFont,
          titleSize: projectTheme.titleFontSize,
          base: projectTheme.baseFont,
          baseSize: projectTheme.baseFontSize,
        },
        colors: {
          primary: projectTheme.primaryColor,
          secondary: projectTheme.secondaryColor,
          headerBackground: projectTheme.headerBgColor,
          sidebarBackground: projectTheme.sidebarBgColor,
          contentBackground: projectTheme.contentBgColor,
          containerBackground: projectTheme.containerBgColor,
          headerText: projectTheme.headerFontColor,
          sidebarText: projectTheme.sidebarFontColor,
        },
      };

      return parsedProjectTheme;
    },
    staleTime: ONE_HOUR,
  });
};
