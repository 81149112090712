import { useResendVerificationEmail, useVerifyEmailToken } from '@apiServices';
import { AnimatedCheckmark, ButtonRow, Card, InputGroup } from '@components';
import { useProfile, useToast } from '@contexts';
import { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

export const ProfileVerifyEmail = () => {
  const { account, hasVerifiedEmail, setView } = useProfile();
  const [verificationCode, setVerificationCode] = useState<Maybe<any>>(null);
  const { showErrorToast, showSuccessToast } = useToast();
  const { mutate: resend, isPending: resendLoading } =
    useResendVerificationEmail();

  const { mutate: verify, isPending: verifyLoading } = useVerifyEmailToken();

  const formValid = verificationCode?.length > 0;

  const handleResend = () => {
    resend(null, {
      onSuccess: () =>
        showSuccessToast({ description: 'Sent verification email.' }),
      onError: (error) => showErrorToast({ description: error.message }),
    });
  };

  const handleVerify = () => {
    verify(verificationCode, {
      onSuccess: (data) => {
        if (data?.result) {
          showSuccessToast({ description: 'Successfully verified email.' });
        } else {
          showErrorToast({
            description: 'Invalid or expired verification code.',
          });
        }
      },
      onError: (error) => showErrorToast({ description: error.message }),
    });
  };

  const handleVerificationCodeChange = (changed) => {
    setVerificationCode(changed.target.value);
  };

  return (
    <>
      <Card className='mb-8 h-full'>
        {hasVerifiedEmail ? (
          <div className={'h-full flex flex-col justify-start'}>
            <div className={'flex flex-col items-center'}>
              <div>
                <AnimatedCheckmark size={'xxLarge'} />
              </div>
              <div className='mt-4 title-text text-center'>
                <span className=''>Email Verified</span>
              </div>
              <div className='mt-3 text-center'>
                <span>
                  Email <b>{account?.email}</b> is verified and assigned to your
                  profile.
                </span>
              </div>
            </div>

            <ButtonRow place={'end'}>
              <button
                onClick={() => {
                  setView('profile-detail');
                }}
                className={'btn btn-primary'}
              >
                Change Email
              </button>
            </ButtonRow>
          </div>
        ) : (
          <div className={'h-full flex flex-col justify-start'}>
            <div className={'mb-8'}>
              <p className={'mb-4'}>
                A verification code has been sent to your email. If not in your
                inbox, please check your spam folder.
              </p>

              <InputGroup
                label='Verification Code'
                value={verificationCode}
                name='verificationCode'
                required={true}
                disabled={verifyLoading}
                onChange={handleVerificationCodeChange}
              />
            </div>

            <ButtonRow place={'end'}>
              <button
                className='btn btn-outline-primary'
                onClick={handleResend}
                disabled={resendLoading || verifyLoading}
              >
                {resendLoading ? (
                  <div className='flex flex-row'>
                    <div className='animate-spin'>
                      <AiOutlineLoading3Quarters size={24} />
                    </div>
                    <span className='pl-2'>Resending...</span>
                  </div>
                ) : (
                  <span>Resend verification email</span>
                )}
              </button>

              <button
                className='btn btn-primary'
                onClick={handleVerify}
                disabled={resendLoading || verifyLoading || !formValid}
              >
                {verifyLoading ? (
                  <div className='flex flex-row'>
                    <div className='animate-spin'>
                      <AiOutlineLoading3Quarters size={24} />
                    </div>
                    <span className='pl-2'>Verifying...</span>
                  </div>
                ) : (
                  <span>Verify</span>
                )}
              </button>
            </ButtonRow>
          </div>
        )}
      </Card>
    </>
  );
};
