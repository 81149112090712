import { usePublicApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';
import { PaymentMethod } from 'tokensoft-shared-types';

export const useGetChainPaymentMethods = (chainId: ID) => {
  const fetchWithClient = usePublicApiClient();

  return useQuery<PaymentMethod[]>({
    queryKey: ['chainPaymentMethods', chainId],
    queryFn: async () => {
      return fetchWithClient(`chains/${chainId}/payment-methods`);
    },
  });
};
