import { usePublicApiClient } from '@apiClients';
import { useQuery } from '@tanstack/react-query';
import { SEOContent } from 'tokensoft-shared-types';

export const useGetProjectSEO = () => {
  const fetchWithClient = usePublicApiClient();

  return useQuery<SEOContent>({
    queryKey: ['project/seo'],
    queryFn: async () => {
      return fetchWithClient('project/seo');
    },
  });
};
