import { usePublicApiClient } from '@apiClients';
import {
  getNonce,
  getNonceSync,
  getProjectPrivacyDocumentsSync,
  useGetProject,
  useGetProjectTheme
} from '@apiServices';
import { SmartContractNetworkSelect } from '@components';
import { DEFAULT_PRIMARY_COLOR } from '@constants';
import { useAuth, useTSWagmi } from '@contexts';
import {
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
  createAuthenticationAdapter,
  lightTheme,
} from '@rainbow-me/rainbowkit';
import { getSignStatement } from '@utils';
import { FC, ReactNode, useMemo } from 'react';
import { SiweMessage } from 'siwe';

declare const window: any;

interface RainbowKitProviderProps {
  children?: ReactNode;
}

export const RainbowkitProvider: FC<RainbowKitProviderProps> = ({
  children,
}) => {
  const { isAuthenticated, authenticate, disconnect } = useAuth();
  const { defaultChain } = useTSWagmi();
  const { data: project } = useGetProject();
  const { data: projectTheme } = useGetProjectTheme();
  const publicApiClient = usePublicApiClient();

  const authenticationAdapter = useMemo(() => {
    console.log('creating new authentication adapter');

    return createAuthenticationAdapter({
      /**
       * Generate a dummy nonce as we won't be using this value anyway.  We will
       * get a nonce from the backend in the createMessage function below.
       */
      getNonce: async () => {
        return getNonce();
      },

      createMessage: ({ nonce, address: messageAddress, chainId }) => {
        console.log(
          'adapter: creating message for wallet address',
          messageAddress,
        );

        nonce = getNonceSync(messageAddress);

        const issuer = project?.name;
        const projectId = project?.id ? `${project.id}` : undefined;
        const documents = getProjectPrivacyDocumentsSync(projectId);

        try {
          return new SiweMessage({
            domain: window.location.host,
            address: messageAddress,
            statement: getSignStatement(
              issuer,
              documents,
              window.location.host,
            ),
            uri: window.location.origin,
            version: '1',
            chainId: chainId,
            nonce: nonce,
          });
        } catch (error) {
          console.error('Failed to create SIWE message:', error);
          throw error;
        }
      },

      getMessageBody: ({ message }) => {
        try {
          console.log('adapter: preparing message');
          return message.prepareMessage();
        } catch (error) {
          console.error('Failed to prepare SIWE message:', error);
          throw error;
        }
      },

      verify: async ({ message, signature }) => {
        console.log('adapter: verifying wallet address', message.address);

        const data = {
          walletAddress: message.address,
          signature,
          message,
          nonce: message.nonce,
        };

        try {
          const response = await publicApiClient('wallets/connect', {
            data: data,
          });

          authenticate({ ...response, walletAddress: message.address });

          console.log(
            'adapter: authentication success for wallet address',
            message.address,
          );
          return Boolean(true);
        } catch (e) {
          console.log({ e });
        }

        return Boolean(false);
      },

      signOut: async () => {
        console.log('adapter: signing out...');
        disconnect();
      },
    });
  }, []);

  return (
    <>
      <RainbowKitAuthenticationProvider
        adapter={authenticationAdapter}
        status={isAuthenticated ? 'authenticated' : 'unauthenticated'}
      >
        <RainbowKitProvider
          initialChain={defaultChain}
          modalSize={'compact'}
          theme={lightTheme({
            accentColor: projectTheme?.colors.primary || DEFAULT_PRIMARY_COLOR,
            accentColorForeground: 'white',
            borderRadius: 'medium',
          })}
          appInfo={{
            appName: 'Tokensoft',
            learnMoreUrl: 'https://www.tokensoft.io',
            disclaimer: ({ Text, Link }) => (
              <Text>
                <div className={'flex justify-center mb-1'}>
                  <SmartContractNetworkSelect />
                </div>
                <span>
                  By connecting your wallet, you agree to the{' '}
                  <Link href='https://www.tokensoft.io/terms'>
                    Terms and Conditions
                  </Link>
                  ,{' '}
                  <Link href='https://www.tokensoft.io/privacy'>
                    Privacy Policy
                  </Link>
                  , and{' '}
                  <Link href='https://www.tokensoft.io/cookies'>
                    Cookie Policy
                  </Link>
                  .
                </span>
              </Text>
            ),
          }}
        >
          {children}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </>
  );
};
